import React from "react";
import { Grid, Typography, Box, Divider, Card, Switch } from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSupplierNameFromId,
  selectSupplierPageTab,
} from "../selectors/supplier.selector";

import TopBreadcrumbs from "../components/topBreadcrumbs.component";

import SupplierInformation from "../components/SupplierDetails/supplierInformation.component";
import InternalInformation from "../components/SupplierDetails/internalInformation.component";
import SupplierTabs from "../components/SupplierDetails/supplierTabs.component";
import SupplierCompliance from "../components/SupplierDetails/supplierComplianceStatus.component";

import LeftSideNavbar from "../components/leftSideNavbar.component";

import SupplierFacilityAuditsTabList from "../components/SupplierDetails/supplierFacilityAuditsTabList.component";

import EditToggleSwitch from "../components/editToggleSwitch.component";
import ConfidentialBusinessInformation from "../components/confidentialBusinessInformation.component";
import SupplierInternalInformationEditModal from "../components/SupplierDetails/EditModals/editSupplierInternalInformation.component";
import SupplierSupplyChainOverview from "../components/SupplierDetails/supplierSupplyChainOverview.component";
import SupplierFactoryList from "../components/SupplierDetails/supplierFactoryList.component";
import { selectSupplierData } from "../selectors/supplier.selector";
import { PageStyles } from "../styles";

const SupplierDetails = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const supplierName = useSelector((state) =>
    selectSupplierNameFromId(state, params.supplierId)
  );
  const supplierData = useSelector(selectSupplierData);
  const selectedTab = useSelector(selectSupplierPageTab);
  
  
  return (
    <Box sx={PageStyles.containerBox}>
      <LeftSideNavbar />
      <Box sx={PageStyles.containerBoxExceptNavbar}>
        <Box sx={PageStyles.firstSectionWithBreadcrumbsAndName}>
          <TopBreadcrumbs />
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography gutterBottom sx={PageStyles.name}>
                {supplierData?.name || supplierName || "-"}
                {supplierData?.metadata?.privateLabel === "Yes" && (
                  <Box
                    sx={PageStyles.tierAndPrivateLabel}
                    size="small"
                    variant="outlined"
                  >
                    Private Label
                  </Box>
                )}
              </Typography>
            </Grid>
            <Grid item>
              <EditToggleSwitch />
            </Grid>
          </Grid>
        </Box>
        <Divider />

        <Grid container spacing={2} sx={PageStyles.secondSectionGrid}>
          <Grid item xs={12} md ={8}>
            <SupplierInformation />
          </Grid>
          {/* Internal Information */}
          {/* <Grid item xs={12}>
            <InternalInformation />
          </Grid> */}
          <Grid item xs={12} md ={4}>
            <ConfidentialBusinessInformation
              title="Confidential Business Information"
              confidential={true}
              type={"supplier"}
            />
          </Grid>
          <Grid item xs={12}>
            <Card sx={PageStyles.cardForTabsAndBelow}>
              <Grid container>
                <Grid item xs={12}>
                  <SupplierTabs />
                </Grid>
                <Grid item xs={12}>
                  {selectedTab === "status" ? (
                    <SupplierSupplyChainOverview />
                  ) : (
                    <SupplierFactoryList />
                  )}
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default SupplierDetails;
