import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Grid,
  Button,
  TextField,
  Typography,
  Card,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getValidData, checkInternalEmail } from "../../../utils/lib";
import { updateAuditData } from "../../../reducers/audit.reducer";
import { AuditStyles } from "../../../styles";
import { selectEmail } from "../../../selectors/login.selector";
import {
  selectAuditIssueCAPData,
} from "../../../selectors/audit.selector";

const AuditInformationEditModal = ({
  open = false,
  onClose,
  factoryData,
  auditData,
}) => {
  const initData = {
    auditType: getValidData(auditData?.metadata?.auditType),
    auditStartDate: getValidData(auditData?.metadata?.auditStartDate),
    auditEndDate: getValidData(auditData?.metadata?.auditEndDate),
    auditScheduler: getValidData(auditData?.metadata?.auditScheduler),
    auditFunder: getValidData(auditData?.metadata?.auditFunder),
    auditFirm: getValidData(auditData?.metadata?.auditFirm),
    auditor: getValidData(auditData?.auditor),
    primaryLanguage: getValidData(auditData?.metadata?.primaryLanguage),
    workersLanguage: getValidData(auditData?.metadata?.workersLanguage),
    state: getValidData(auditData?.state),
    originalAuditScore: getValidData(auditData?.metadata?.originalAuditScore),
    auditScore: getValidData(auditData?.risk?.auditScore),
  };

  const userEmail = useSelector(selectEmail);
  const isInternalUser = checkInternalEmail(userEmail);

  const [formData, setFormData] = useState(initData);
  const [initialData, setInitialData] = useState({});

  useEffect(() => {
    setInitialData(initData);
    setFormData(initData);
  }, [auditData, factoryData]);

  const dispatch = useDispatch();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const changes = Object.keys(formData).reduce((acc, key) => {
      if (formData[key] !== initialData[key]) {
        acc[key] = formData[key];
      }
      return acc;
    }, {});

    if (Object.keys(changes).length > 0) {
      dispatch(
        updateAuditData({
          organisationId: auditData?.organisationId,
          supplierId: auditData?.supplierId,
          factoryId: auditData?.factoryId,
          auditId: auditData?._id,
          changes,
        })
      );
    }
    onClose();
  };


  // Easy copy of all the finding details and legal reference for QA
  const auditDetailsData = useSelector(selectAuditIssueCAPData);

  const aggregatedDetails = auditDetailsData
    .map((item, index) => 
      `${index + 1}.\nIssue Details: ${item.issueDetail}\n\nAudit Code or Legal reference: ${item.auditBasis}`
    )
    .join('\n\n');


  // ### Added handleCopy Function ###
  const handleCopy = () => {
    console.log(aggregatedDetails);
    navigator.clipboard
      .writeText(aggregatedDetails)
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  return (
    <Box sx={AuditStyles.containerBox}>
      <form onSubmit={handleSubmit}>
        <Grid container justifyContent={"space-between"} sx={{ mb: 1 }}>
          <Grid item>
            <Typography
              variant="h5"
              sx={{
                fontWeight: 700,
                fontSize: 16,
                color: "#344054",
                display: "inline-block",
              }}
            >
              Edit Audit Information
            </Typography>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              sx={{
                backgroundColor: "#585aeb",
                borderRadius: "8px",
                textTransform: "none",
              }}
              size="small"
            >
              Save Changes
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          {/* Only Internal User can change this data */}
          {isInternalUser && (
            <>
            <Grid item xs={12}>
            <Button
              variant="contained"
              onClick={handleCopy}
              sx={{
                backgroundColor: "orange",
                borderRadius: "8px",
                textTransform: "none",
                color: "#fff",
              }}
            >
              Copy all findings details and legal reference to clipboard
            </Button>
          </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth margin="normal">
                  <InputLabel id="audit-state-label" sx={{ color: "orange" }}>
                    Audit State
                  </InputLabel>
                  <Select
                    labelId="audit-state-label"
                    name="state"
                    value={formData["state"]}
                    onChange={handleChange}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderRadius: "8px", // Set the border-radius here
                          borderColor: "orange", // Make the border orange
                        },
                      },
                      "& .MuiSelect-select": {
                        color: "orange", // Make the text orange
                      },
                    }}
                  >
                    <MenuItem value="test" sx={{ color: "orange" }}>
                      Internal - Not Approved
                    </MenuItem>
                    <MenuItem value="active" sx={{ color: "green" }}>
                      Internal - Approved
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label={"Original Audit Score"}
                  name={"originalAuditScore"}
                  variant="outlined"
                  value={formData["originalAuditScore"]}
                  onChange={handleChange}
                  margin="normal"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderRadius: "8px", // Set the border-radius here
                        borderColor: "orange",
                      },
                    },
                    "& .MuiInputBase-input": {
                      color: "orange", // Make the text orange
                    },
                    "& .MuiInputLabel-root": {
                      color: "orange", // Make the label orange
                    },
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  disabled
                  label={"Audit Score"}
                  name={"auditScore"}
                  variant="outlined"
                  value={`${formData["auditScore"]} (auto calculated)`}
                  onChange={handleChange}
                  margin="normal"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderRadius: "8px", // Set the border-radius here
                        borderColor: "orange",
                      },
                    },
                    "& .MuiInputBase-input": {
                      color: "orange", // Make the text orange
                    },
                    "& .MuiInputLabel-root": {
                      color: "orange", // Make the label orange
                    },
                  }}
                />
              </Grid>
            </>
          )}

          {Object.keys(initData).map(
            (field, index) =>
              field !== "state" &&
              field !== "auditScore" &&
              field !== "originalAuditScore" && (
                <Grid item xs={4} key={index}>
                  <TextField
                    fullWidth
                    label={
                      field === "auditType"
                        ? "Auditor Scheme"
                        : field.replace(/([A-Z])/g, " $1").trim()
                    }
                    name={field}
                    variant="outlined"
                    value={formData[field]}
                    onChange={handleChange}
                    margin="normal"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderRadius: "8px", // Set the border-radius here
                        },
                      },
                    }}
                  />
                </Grid>
              )
          )}
        </Grid>
      </form>
    </Box>
  );
};

export default AuditInformationEditModal;
