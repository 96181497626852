import React from "react";
import {
  Grid,
  Typography,
  Box,
  Divider,
  Button,
  Skeleton,
  Card,
  Switch,
  Drawer,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import TopBreadcrumbs from "../components/topBreadcrumbs.component";
import LeftSideNavbar from "../components/leftSideNavbar.component";
import {
  selectAuditNameFromId,
  selectAuditPageTab,
  selectAuditXlsxFile,
  selectSelectedAuditIssueId,
  selectAuditData,
} from "../selectors/audit.selector";
import AuditInformation from "../components/AuditDetails/auditInformation.component";
import ListOfAuditDocuments from "../components/AuditDetails/listOfAuditDocuments.component";
import AuditTabs from "../components/AuditDetails/auditTabs.component";
import AuditIframe from "../components/AuditDetails/auditIframe.component";
import {
  selectAuditPageFactoryDataTitle,
  selectFactoryData,
} from "../selectors/factory.selector";
import EditToggleSwitch from "../components/editToggleSwitch.component";
import AuditsTable from "../components/AuditDetails/auditsTable.component";
import FacilityIssuesCard from "../components/FacilityDetails/facilityIssuesCard.component";
import AuditIssuesTable from "../components/AuditDetails/auditIssuesTable.component";
import AuditReportDetails from "./auditReportDetails.page";
import { setSelectedAuditIssueId } from "../reducers/audit.reducer";
import AuditDocumentStorage from "../components/AuditDetails/auditDocumentStorage.component";
import { toggleEditModeForAudit } from "../reducers/misc.reducer";
import { PageStyles } from "../styles";
import { uploadAuditReport, regenerateCap } from "../reducers/audit.reducer"; // Import the regenerateCap action
import { selectEmail } from "../selectors/login.selector";
import { checkInternalEmail } from "../utils/lib";
import { selectSaveStatus } from "../selectors/misc.selector";

const AuditDetails = () => {
  const params = useParams();
  const dispatch = useDispatch();
  // Drawer state management
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  const userEmail = useSelector(selectEmail);
  const isInternalUser = checkInternalEmail(userEmail);
  const isSomethingUnsaved = useSelector(selectSaveStatus);

  // Update the drawer based on the selectedAuditIssueId
  const selectedAuditIssueId = useSelector(selectSelectedAuditIssueId);
  React.useEffect(() => {
    // Ensure the drawer opens only if there is a valid, non-null `selectedAuditIssueId`
    if (selectedAuditIssueId) {
      setIsDrawerOpen(true);
    } else {
      setIsDrawerOpen(false);
    }
  }, [selectedAuditIssueId]);

  // Handle close drawer
  const handleDrawerClose = () => {
    if (isSomethingUnsaved === "updated") {
      alert("You have unsaved changes on the page. Please save or discard them before closing.");
    } else {
      dispatch(setSelectedAuditIssueId(null));
      dispatch(toggleEditModeForAudit(false));
      setIsDrawerOpen(false);
    }
  };
  const auditName = useSelector((state) =>
    selectAuditNameFromId(state, params.auditId)
  );
  const selectedAuditData = useSelector(selectAuditData);
  const selectedFactoryData = useSelector(selectFactoryData);
  const selectedTab = useSelector(selectAuditPageTab);

  // Define organisationId, auditId, supplierId, and factoryId
  const organisationId = params.organisationId;
  const auditId = params.auditId;
  const supplierId = params.supplierId;
  const factoryId = params.factoryId;

  // Handle file selection
  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      dispatch(
        uploadAuditReport(
          { organisationId, auditId, supplierId, factoryId, file },
          { file }
        )
      );
    }
  };

  // Handle regenerate export
  const handleRegenerateExport = () => {
    dispatch(
      regenerateCap({ organisationId, auditId, supplierId, factoryId })
    );
  };

  return (
    <Box sx={PageStyles.containerBox}>
      <LeftSideNavbar />
      <Box sx={PageStyles.containerBoxExceptNavbar}>
        <Box sx={PageStyles.firstSectionWithBreadcrumbsAndName}>
          <TopBreadcrumbs />
          <Grid container justifyContent="space-between">
            <Grid>
              <Typography gutterBottom sx={PageStyles.name}>
                {selectedAuditData?.metadata?.auditType} (
                {moment(selectedAuditData?.metadata?.auditEndDate).format(
                  "MMM. DD, YYYY"
                )}
                )
              </Typography>
              {selectedAuditData?.state &&
                selectedAuditData.state === "test" && (
                  <Typography color="red" sx={{ fontSize: "10px" , mb:1}}>
                    Internal - Not Approved
                  </Typography>
                )}
            </Grid>
            <Grid item>
              <EditToggleSwitch />
            </Grid>
          </Grid>
        </Box>
        <Divider />

        <Grid container spacing={2} sx={PageStyles.secondSectionGrid}>
          <Grid item xs={12}>
            <Card sx={PageStyles.auditContainerCard}>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                  <AuditInformation />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={5} xl={5} sx={{ p: 2 }}>
                  <AuditIssuesTable />
                </Grid>
                <Grid item xs={12} sx={{ mt: 4, mb: 2 }}>
                  <AuditTabs />
                </Grid>
                <Grid item xs={12}>
                  {selectedTab === "issues" ? (
                    <AuditsTable />
                  ) : selectedTab === "document" ? (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "rgba(234, 236, 240, 0.5)",
                          padding: "8px 16px",
                          borderRadius: "8px",
                          mb: 2,
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: 14,
                            color: "rgba(0, 0, 0, 0.6)",
                            fontWeight: 500,
                            fontStyle: "italic",
                            textAlign: "center",
                          }}
                        >
                          Data Migration and Cleaning in Progress. Upload Disabled.
                        </Typography>
                        <Button
                          variant="outlined"
                          onClick={() => document.getElementById('file-upload-input').click()}
                          sx={{
                            mt: 1,
                            color: "#3538CD",
                            fontSize: "14px",
                            fontWeight: 600,
                            padding: "8px 12px",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "8px",
                            border: "1px solid #A4BCFD",
                            background: "#FFF",
                            boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                            height: "fit-content",
                            textTransform: "none",
                          }}
                          disabled={!isInternalUser}
                        >
                          Upload
                        </Button>
                        <input
                          type="file"
                          id="file-upload-input"
                          style={{ display: "none" }}
                          onChange={handleFileSelect}
                        />
                      </Box>
                      <ListOfAuditDocuments includeType="document" />
                    </>
                  ) : (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "rgba(234, 236, 240, 0.5)",
                          padding: "8px 16px",
                          borderRadius: "8px",
                          mb: 2,
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: 14,
                            color: "rgba(0, 0, 0, 0.6)",
                            fontWeight: 500,
                            fontStyle: "italic",
                            textAlign: "center",
                          }}
                        >
                          Data Migration and Cleaning in Progress. Export disabled.
                        </Typography>
                        <Button
                          variant="outlined"
                          onClick={handleRegenerateExport}
                          sx={{
                            mt: 1,
                            color: "#3538CD",
                            fontSize: "14px",
                            fontWeight: 600,
                            padding: "8px 12px",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "8px",
                            border: "1px solid #A4BCFD",
                            background: "#FFF",
                            boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                            height: "fit-content",
                            textTransform: "none",
                          }}
                          disabled={!isInternalUser}
                        >
                          Regenerate Export
                        </Button>
                      </Box>
                      <ListOfAuditDocuments includeType="export" />
                    </>
                  )}
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Drawer
        anchor="bottom"
        open={isDrawerOpen}
        onClose={handleDrawerClose}
        sx={{
          "& .MuiDrawer-paper": {
            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
            maxHeight: "98%",
            maxWidth: "98%",
            display: "flex",
            justifySelf: "center",
          },
        }}
      >
        <AuditReportDetails handleDrawerClose={handleDrawerClose} />
      </Drawer>
    </Box>
  );
};

export default AuditDetails;