import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Divider,
  Tooltip,
  IconButton,
  Button,
} from "@mui/material";
import SortIcon from "@mui/icons-material/Sort";
import { selectFactoryIssuesData } from "../../selectors/factory.selector";
import { useDispatch } from "react-redux";
import { selectAuditData } from "../../selectors/audit.selector";
import { updateAuditData } from "../../reducers/audit.reducer";
import { useSelector } from "react-redux";
import { selectAuditIssuesData } from "../../selectors/audit.selector";
import { AuditStyles, ComponentStyles } from "../../styles";
const AuditIssuesTable = () => {
  const issuesData = useSelector(selectAuditIssuesData);

  const [filteredIssues, setFilteredIssues] = useState(
    issuesData.detailedIssues || []
  );
  useEffect(() => {
    setFilteredIssues(issuesData.detailedIssues || []);
  }, [issuesData.detailedIssues]);
  const [isSorted, setIsSorted] = useState(false);
  const handleSort = () => {
    const sortedData = [...filteredIssues].sort((a, b) => {
      if (isSorted) {
        return a.category.localeCompare(b.category);
      } else {
        return b.category.localeCompare(a.category);
      }
    });
    setFilteredIssues(sortedData);
    setIsSorted(!isSorted);
  };

  const handleFilter = (key) => {
    const filteredData = issuesData.detailedIssues.filter(
      (issue) => issue[key] > 0
    );
    setFilteredIssues(filteredData);
  };
  
  // Calculate Audit Score on frontend.
  const calculateAuditScore = (detailedIssues) => {
    const scoreDeductions = {
      'Minor': 1,
      'Major': 3,
      'Critical': 10,
      'ZT': 50
    };

    let totalDeduction = 0;

    detailedIssues.forEach(issue => {
      const deductionPerIssue = scoreDeductions[issue.category] || 0;
      const totalIssues = issue.open + issue.totalClosed;
      totalDeduction += deductionPerIssue * totalIssues;
    });

    return (100 - totalDeduction).toString();
  };

  const auditData = useSelector(selectAuditData);
  const dispatch = useDispatch();

  useEffect(() => {
    const auditScoreCalculated = calculateAuditScore(issuesData.detailedIssues);

    // Only dispatch if the calculated score is different from the current score
    if (auditScoreCalculated !== auditData?.risk?.auditScore) {
      dispatch(
        updateAuditData({
          organisationId: auditData?.organisationId,
          supplierId: auditData?.supplierId,
          factoryId: auditData?.factoryId,
          auditId: auditData?._id,
          changes: { auditScore: auditScoreCalculated },
        })
      );
    }
  }, [issuesData.detailedIssues, dispatch]);

  return (
    <Paper elevation={1} sx={AuditStyles.issuesTableContainerPaper}>
      <Box sx={{ height: 300, overflow: "auto" }}>
        <Table stickyHeader>
          <TableHead sx={{ zIndex: 1 }}>
            <TableCell
              sx={{
                ...AuditStyles.issuesTableCell,
                px: 0,
              }}
            ></TableCell>
            <TableCell sx={AuditStyles.issuesTableCell}>
              <Typography sx={ComponentStyles.headingsName}>Total</Typography>
            </TableCell>
            <TableCell sx={AuditStyles.issuesTableCell}>
              <Typography sx={ComponentStyles.headingsName}>Closed</Typography>
            </TableCell>
            <TableCell sx={AuditStyles.issuesTableCell}>
              <Typography sx={ComponentStyles.headingsName}>Open</Typography>
            </TableCell>
            <TableCell sx={AuditStyles.issuesTableCell}>
              <Typography sx={ComponentStyles.headingsName}>
                Past Due
              </Typography>
            </TableCell>
          </TableHead>
          <TableRow>
            <TableCell sx={AuditStyles.issuesTableCell}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box sx={ComponentStyles.alignBoxItemsCenter}>
                  <Typography sx={ComponentStyles.headingsName}></Typography>
                </Box>
              </Box>
            </TableCell>
            <TableCell sx={AuditStyles.issuesTableCellWithSolidRightBorder}>
              <Box sx={ComponentStyles.alignBoxItemsCenter}>
                <Typography sx={ComponentStyles.headingsName}>
                  {issuesData.total}
                </Typography>
              </Box>
            </TableCell>
            <TableCell sx={AuditStyles.issuesTableCellWithDottedRightBorder}>
              <Box sx={ComponentStyles.alignBoxItemsCenter}>
                <Typography sx={ComponentStyles.headingsName}>
                  {issuesData.issuesSummary.totalClosed}
                </Typography>
              </Box>
            </TableCell>
            <TableCell sx={AuditStyles.issuesTableCellWithDottedRightBorder}>
              <Box sx={ComponentStyles.alignBoxItemsCenter}>
                <Typography sx={ComponentStyles.headingsName}>
                  {issuesData.issuesSummary.open}
                </Typography>
              </Box>
            </TableCell>

            <TableCell sx={AuditStyles.issuesTableCell}>
              <Box sx={ComponentStyles.alignBoxItemsCenter}>
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: 15,
                    color: "#344054",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {issuesData.issuesSummary.pastDue}
                </Typography>
              </Box>
            </TableCell>
          </TableRow>

          <TableBody>
            {filteredIssues.map((issue, index) => (
              <TableRow key={index} sx={{ cursor: "default" }}>
                <TableCell
                  sx={{
                    fontWeight: 500,
                    fontSize: 16,
                    color: issue.color,
                    maxWidth: "150px",
                    pr: 0,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    cursor: "default",
                    borderBottom: "none",
                    py: 1.5,
                  }}
                >
                  {issue.category.length > 15 ? (
                    <Tooltip
                      title={issue.category}
                      placement="top"
                      sx={{ width: "32px", color: issue.color }}
                    >
                      <span>{issue.category}</span>
                    </Tooltip>
                  ) : (
                    issue.category
                  )}
                </TableCell>

                <TableCell
                  sx={{
                    ...AuditStyles.issuesTableCellWithSolidRightBorder,
                    borderBottom: "none",
                  }}
                >
                  <Box sx={ComponentStyles.alignBoxItemsCenter}>
                    <Typography sx={ComponentStyles.headingsName}>
                      {issue.open + issue.totalClosed || "0"}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell
                  sx={{
                    ...AuditStyles.issuesTableCellWithDottedRightBorder,
                    borderBottom: "none",
                  }}
                >
                  <Box sx={ComponentStyles.alignBoxItemsCenter}>
                    <Typography sx={ComponentStyles.headingsName}>
                      {issue.totalClosed || "0"}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell
                  sx={{
                    ...AuditStyles.issuesTableCellWithDottedRightBorder,
                    borderBottom: "none",
                  }}
                >
                  <Box sx={ComponentStyles.alignBoxItemsCenter}>
                    <Typography sx={ComponentStyles.headingsName}>
                      {issue.open || "0"}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 500,
                    fontSize: 16,
                    borderBottom: "none",
                    py: 1.5,
                  }}
                >
                  <Box sx={ComponentStyles.alignBoxItemsCenter}>
                    <Typography sx={ComponentStyles.headingsName}>
                      {issue.pastDue || "0"}
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Paper>
  );
};

export default AuditIssuesTable;
